<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12 col-xl-6">
        <h5 class="blue-text font-weight-bold">請求書一覧</h5>
      </div>
      <div class="col-12 col-md-12 col-lg-12 col-xl-6">
        <div id="top-ation">
          <div class="align-middle search-label text-right center">
            <span class="font-weight-bold">フィルター:</span>
          </div>
          <div class="no-border" style="align-items: center;">
            <div class="form-group">
              <!--<label for="started_at" class="col-form-label">請求日:</label>-->
              <div class="search shadow-1">
                <input type="date" id="searchDate" name="searchDate" v-on:change="getResults()" v-model="started_at">
                <!--<div><strong class="error text-danger" v-for="(value, i) in started_at" :key="i">{{ value }}</strong></div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div id="staging-list" class="mt-5" v-if="invoiceListing">
          <div v-if="invoiceListing.length > 0">
            <div
              class="row m-0 pt-3 pb-3 mb-2 item"
              v-for="(el, i) in invoiceListing"
              :key="i"
            >
              <div class="col-12 col-md-12 col-lg-12 col-xl-9">
                <div class="row details">
                  <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                    <div class="row">
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">法人名</p>
                        <p class="ellipsis-1">{{ el.company_name }}</p>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">名前(姓)</p>
                        <p class="ellipsis-1">{{ el.last_name }}</p>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">名前(名)</p>
                        <p class="ellipsis-1">{{ el.first_name }}</p>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">請求日</p>
                        <p class="ellipsis-1">
                          {{ formatDate(el.payment_month) }}
                        </p>
                      </div>
                      <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-2">
                        <p class="mb-1 grey-text font-weight-bold">合計金額</p>
                        <p class="ellipsis-1">
                          {{
                            el ? formatPrice(el.amount) : 'N/A'
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12 col-lg-12 col-xl-3">
                <div class="row actions">
                  <div class="col-12 mb-3">
                    <router-link
                      :to="{
                        name: 'adminRoute-invoice-payment-view',
                        params: {
                          id: el.user_id,
                        },
                      }"
                    >
                      <button class="btn btn-ds blue white-text" v-waves.light>
                        詳細を表示
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <pagination
              class="mt-3 mb-0 mx-0"
              :data="pagination.data"
              :show-disabled="pagination.showDisabled"
              :size="pagination.size"
              :align="pagination.align"
              :limit="1"
              @pagination-change-page="paginateData"
            />
          </div>
          <div v-else>
            <b-card class="shadow-1 pt-5 pb-5 text-center" no-header no-footer>
              <img src="/img/icons/ico-info.svg" alt="ico-info" />
              <h3 class="m-0 mt-3 grey-text">表示できる内容がありません。</h3>
            </b-card>
          </div>
        </div>
        <div class="mt-5" v-else>
          <div class="row m-0 pt-3 pb-3 item" v-for="(el, i) in 10" :key="i">
            <div class="col-12 col-md-12 col-lg-12 col-xl-9">
              <div class="row details">
                <div class="col-12 col-md-12 col-lg-12 col-xl-10">
                  <div class="row">
                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="80%"></b-skeleton>
                    </div>
                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="50%"></b-skeleton>
                    </div>
                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="70%"></b-skeleton>
                    </div>
                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="50%"></b-skeleton>
                    </div>
                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="80%"></b-skeleton>
                    </div>
                    <div class="col-6 col-md-4 col-lg-4 col-xl-4 mb-4">
                      <b-skeleton width="100%"></b-skeleton>
                      <b-skeleton width="80%"></b-skeleton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-3">
              <div class="row actions">
                <div class="col-12 mb-2">
                  <b-skeleton
                    type="button"
                    height="56px"
                    width="100%"
                  ></b-skeleton>
                </div>
                <div class="col-12 mb-2">
                  <b-skeleton
                    type="button"
                    height="56px"
                    width="100%"
                  ></b-skeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Services
import { globalService_DownloadIMG } from '../../services/global';
import { adm_invoicePayment_GetList } from '../../services/admin/staging';
import { cus_stagingService_DeleteSR } from '../../services/customer/staging';
// Import Moment JS
import moment from 'moment';
//import common
import { getRequestItemVersions } from '../../common/Functions';
import { formatDate, formatPrice, formatUserName } from '../../common/Utility';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | List',
    };
  },

  data() {
    return {
      errors: '',
      started_at: null,
      invoiceListing: null,
      pagination: {
        data: null,
        showDisabled: false,
        size: 'default',
        align: 'center',
      },
      itemToBeRemoved: null,
    };
  },
  watch: {
    $route() {
      this.getResults(this.getUrlQueryParamsPage());
    },
  },
  mounted() {
    this.getResults(this.getUrlQueryParamsPage());
  },
  methods: {
    formatDate,
    formatPrice,
    formatUserName,
    getUrlQueryParamsPage() {
      let currentRouteURLPrams = new URLSearchParams(location.search);
      return currentRouteURLPrams.get('page');  
    },
    getResults(page) {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');

      _this.invoiceListing = null;

      adm_invoicePayment_GetList({
        page: page ? page : 1,
        token: accessToken,
        searchDate: _this.started_at ? _this.started_at : '',
      })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.invoiceListing = data.data;
          _this.pagination.data = data;
        })
        .catch((error) => {
          console.log(error);
          _this.$bvToast.toast(
            'データの取得中にエラーが起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );
        });

      _this.$store.state.currentPaginationPage = page;
    },
    paginateData(page) {
      let _this = this;
      let currentRouteURLPrams = new URLSearchParams(location.search);
      let url_page = currentRouteURLPrams.get('page');
      if (url_page != page) {
        _this.$router.push({
          name: 'adminRoute-invoice-payment-list',
          query: { page: page },
        });
      }
    },
    /*searchData(e) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.getResults();
      }, 600);
    },
    setCancelItemModal(id) {
      this.itemToBeRemoved = id;
      this.$bvModal.show('cancel-item-modal');
    },*/

    /*removeItem() {
      let _this = this;

      _this.$bvModal.hide('cancel-item-modal');

      _this.$store.state.modalLoaderMessage = '削除中';
      _this.$bvModal.show('modal-loader');

      let accessToken = localStorage.getItem('_accessToken');

      console.log(_this.itemToBeRemoved, accessToken);
      cus_stagingService_DeleteSR({
        id: _this.itemToBeRemoved,
        token: accessToken,
      })
        .then((response) => {
          console.log(response);

          _this.getResults();

          _this.$bvToast.toast('You have successfully canceled the item.', {
            title: 'キャンセルに成功しました',
            autoHideDelay: 5000,
            variant: 'success',
            appendToast: true,
          });

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        })
        .catch((error) => {
          console.log(error);

          _this.$bvToast.toast(
            '依頼の削除中にエラー起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        });
    },*/
    /*downloadImages(requestItems) {
      let link = document.createElement('a');
      document.body.appendChild(link);

      let accessToken = localStorage.getItem('_accessToken');

      //download current confirmed image for each request items
      requestItems.forEach((ri) => {
        const { current_version } = getRequestItemVersions(ri);
        globalService_DownloadIMG({
          token: accessToken,
          path: current_version.result_file_path,
        }).then((response) => {
          let url = window.URL.createObjectURL(response.data);
          link.href = url;
          link.setAttribute(
            'download',
            `SR-${ri.id}_current_version_${current_version.delivery_date}`
          );
          link.click();
        });
      });
    },*/
  },
};
</script>

<style scoped>
/* Init Style */
/* Custom Style */
/*#top-ation {
  display: flex;
}*/

#top-ation .search {
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid #d1d1d1;
}

#top-ation .search .icon {
  font-size: 24px;
  padding: 10px;
  text-align: center;
  background: #fff;
  color: #d1d1d1;
}

#top-ation .search input {
  width: 100%;
  border: none;
  padding: 10px;
}

.search {
  border: 1px solid #d1d1d1;
  width: 50px;
}

#top-ation .search input:focus {
  outline: none;
}

#staging-list .item {
  margin-bottom: 20px;
  border-radius: 20px;
  background: #f5f5f5;
}
#top-ation .search-label {
  width: 250px;
  margin-right: 20px;
}
#staging-list .item .details .img-carousel {
  height: 130px;
  background: #e9e9e9;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.center {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}
.no-border {
  border: none !important;
}
@media only screen and (max-width: 767px) {
  #top-ation {
    display: block;
  }
  #top-ation .search {
    margin-bottom: 14px;
  }
  #staging-list .item .details .img-carousel {
    height: 250px;
  }
}

@media only screen and (max-width: 1199px) {
  #staging-list .item .details {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  #staging-list .item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  #staging-list .item .details .img-carousel {
    height: 350px;
  }
}
</style>
